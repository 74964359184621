import TORUS from "./namespace";

import {
  INVIEW_OBSERVER,
  INVIEW_ELEMENTS,
  optimizeAttribute,
  initClass,
  getIterableElement,
  expandCluster,
  callFunction,
  createPropertiesObject,
} from "./util";

TORUS.Parent = class {
  constructor(element, options) {
    /** this element */
    this.element = element;

    this.element.torusInitializedParent = true;

    if (options) {
      if (options.trigger) {
        this.element.dataset.torParent = this.element.dataset.torParent ? this.element.dataset.torParent + " " + options.trigger : options.trigger;
      }
    }

    /** Optimize and replace original [data-tor-parent] attribute */
    this.element.dataset.torParent = expandCluster(optimizeAttribute(this.element.dataset.torParent, true));

    /** Replace all ` ` spaces in (<value>) definition and split into an array */
    this.dataset = this.element.dataset.torParent.replace(/\((.*?)\)+/g, match => match.replace(/ +/g, "░")).split(" ");

    /** Create store objects */
    this.is = this.is || {};
    this.has = this.has || {};
    this.attributes = this.attributes || {};

    this._getterSetter();
    this._sortAttributes();
    this._addToElementsSet();
  }

  /**
   * ------------------------------------------------------------------------
   * Define getter and setter functions
   * ------------------------------------------------------------------------
   */

  _getterSetter() {
    /** Setter */
    this.set = {
      inview: (status, force) => {
        if (!this.is.inviewOffset || force) {
          this.is.inview = status;

          if (status) {
            requestAnimationFrame(() => this.element.classList.add("inview"));
          } else {
            if (this.is.inviewRevert) {
              this.element.classList.remove("inview");
            }
          }
        }
      },
    }

    /** Runner */
    this.run = {
      inview: () => {
        INVIEW_OBSERVER.observe(this.element);
      },
    }

  }

  /**
   * ------------------------------------------------------------------------
   * Sort attributes
   * ------------------------------------------------------------------------
   */

  _sortAttributes() {
    let temp = {};

    for (const dataAttribute of this.dataset) {
      temp.static = temp.static || [];
      temp.static.push(dataAttribute);
    }

    for (const [group, array] of Object.entries(temp)) {
      createPropertiesObject(this, array, group);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Add element to corresponding set
   * ------------------------------------------------------------------------
   */

  _addToElementsSet() {
    if (/inview/.test(this.dataset)) {
      this.is.inviewElement = true;
      INVIEW_ELEMENTS.add(this);
      this.run.inview();
    }
  }

  /**
   * ------------------------------------------------------------------------------------------------------------------------------------------------
   * Initialization
   * ------------------------------------------------------------------------------------------------------------------------------------------------
   */

  static init(elements, options) {
    elements = getIterableElement(elements || "[data-tor-parent~='inview']");

    if(elements) {
      initClass({ name: "Parent", elements: elements, options: options });
    }
  }
}

export default TORUS.Parent;