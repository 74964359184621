import TORUS from "./namespace";

import {
  MUTATION,
  INTERSECTION_OBSERVER,
  INVIEW_OBSERVER,
  CSS_SET,
  CSS_PROPERTIES,
  CSS_BREAKPOINTS,
  INVIEW_ELEMENTS,
  SCROLL_ELEMENTS,
  MOUSE_ELEMENTS,
  CLASS_SCROLL_ELEMENTS,
  SVG_ELEMENTS,
  WINDOW,
  optimizeAttribute,
  getValuesForCurrentResolution,
  getValueData,
  getCSSVariable,
  initClass,
  getIterableElement,
  insertStylesheet,
  expandCluster,
  getPercents,
  getMaxSide,
  callFunction,
  createPropertiesObject,
} from "./util";

TORUS.Main = class {
  constructor(element) {
    /** this element */
    this.element = element;

    this.element.torMainInit = true;

    /** Optimize and replace original [data-tor] attribute */
    this.element.dataset.tor = expandCluster(optimizeAttribute(this.element.dataset.tor, true));

    /** Replace all ` ` spaces in (<value>) definition and split into an array */
    this.dataset = this.element.dataset.tor.replace(/\((.*?)\)+/g, match => match.replace(/ +/g, "░")).split(" ");

    /** Create store objects */
    this.is = this.is || {};
    this.has = this.has || {};
    this.attributes = this.attributes || {};
    this.bounds = this.bounds || {};

    /** Getters and Setters init */
    this._getterSetter();

    /** Call functions */
    this._sortAttributes();
    this.get.bounds();
    this._addToElementsSet();
  }

  /**
   * ------------------------------------------------------------------------
   * Define getter and setter functions
   * ------------------------------------------------------------------------
   */

  _getterSetter() {
    /** Getter */
    this.get = {
      bounds: () => {
        this._getBounds();
      }
    }

    /** Setter */
    this.set = {
      bounds: (bounds, svgParentRect) => {
        this._setBounds(bounds, svgParentRect);
        // TODO:
        // requestAnimationFrame(() => this.element.classList.remove("tor-hidden"));
      },

      intersecting: (status) => {
        this.is.intersecting = status;
      },

      inview: (status, force) => {
        if (!this.is.inviewOffset || force) {
          this.is.inview = status;

          if (status) {
            /**
             * Chrome bug
             *
             * Sometimes when user start to scroll immediately before DOMContentReady,
             * the `.inview` class is being added immediately without CSS transition.
             * To hack this, we need to add a little bit of delay if DOM is not ready
             */
            let delay = 0;
            if (WINDOW.isChrome) {
              delay = WINDOW.DOMReady ? 0 : 200;
            }

            requestAnimationFrame(() => {
              setTimeout(() => {
                this.element.classList.add("inview");
              }, delay);
            });
          } else {
            if (this.is.inviewRevert) {
              requestAnimationFrame(() => this.element.classList.remove("inview"));
            }
          }
        }
      },
    }

    /** Runner */
    this.run = {
      inview: () => {
        INVIEW_OBSERVER.observe(this.element);
      },
      event: (event, params) => {
        this._runEvent(event, params);
      },
      classScroll: () => {
        this._onClassScroll();
      },
    }

  }

  /**
   * ------------------------------------------------------------------------
   * Sort attributes
   * ------------------------------------------------------------------------
   */

  _sortAttributes() {
    let temp = {};

    for (const dataAttribute of this.dataset) {
      if (/class./.test(dataAttribute)) {
        temp.class = temp.class || [];
        temp.class.push(dataAttribute);
      } else if (/mouse|scroll|sensor/.test(dataAttribute)) {
        temp.dynamic = temp.dynamic || [];
        temp.dynamic.push(dataAttribute);
        this.is.dynamicAttribute = true;
      } else if (/loop:/.test(dataAttribute)) {
        temp.loop = temp.loop || [];
        temp.loop.push(dataAttribute);
      } else {
        temp.static = temp.static || [];
        temp.static.push(dataAttribute);

        if (/^inview(.*?)(pull\.up|@transform=translateY\(\d)/.test(dataAttribute)) {
          this.has.originalPosition = true;
          this.element.classList.add("tor-original-position");
        }
      }
    }

    for (const [group, array] of Object.entries(temp)) {
      createPropertiesObject(this, array, group);

      group === "static" && this._CSSAddToSet(this.attributes.static);
      group === "loop"   && this._LOOPCreate();
      group === "class"  && this._CLASSAddListeners(array);
    }

    if (this.attributes.dynamic && this.is.dynamicAttribute) {
      this.attributes.dynamic.styles = {};
      this.attributes.dynamic.currentStyles = new Set();

      // TODO:
      // this.element.classList.add("tor-hidden");
    }

    /** Create hover hit area for some effects */
    if (/hover:(.*?)(push|pull|rotate)/.test(this.dataset)) {
      if (!this.element.querySelector(".tor-hit-area")) {
        const hit = document.createElement("span");
        hit.classList.add("tor-hit-area");
        this.element.appendChild(hit);
      }
    }

    if (/:block\(/.test(this.dataset)) {
      let block = document.createElement("span");
      block.classList.add("tor-block-element");
      this.element.appendChild(block);
    }

    // this.element.style.setProperty("transform", "none", "important");
  }

  /**
   * ------------------------------------------------------------------------
   * Create CSS looped animations rules
   * ------------------------------------------------------------------------
   */

  _LOOPCreate() {
    if (!this.attributes.loop) {
      return;
    }

    let names = [];
    let durations = [];
    let timings = [];
    let directions = [];
    let delays = [];
    let pauses = {};
    let hasPause = false;
    let indexes = {};

    for (const [i, attribute] of Object.entries(this.attributes.loop.loop)) {
      let radiate = /(radiate)(.*?)+/.exec(attribute.property.name);
      if (radiate) {
        attribute.property.name = attribute.property.name.replace(radiate[0], radiate[1])
      }

      let name = `loop-${attribute.property.name.replace(/\./g, "-")}`;
      let duration = `calc(var(--tor-${name}-duration) * var(--tor-${name}-speed,1))`;
      let timing = `var(--tor-${name}-timing)`;
      let direction = `var(--tor-${name}-direction)`;

      let currentEnd = getValuesForCurrentResolution(attribute, 1);
      let currentStart = getValuesForCurrentResolution(attribute, 1).start;

      pauses[name] = {
        index: Number(i),
        pause: null,
        iterations: 0,
        currentIteration: 0,
      };

      indexes[i] = false;

      if (currentEnd.value) {
        this.element.style.setProperty(`--tor-${name}-value`, `${currentEnd.value}${currentEnd.unit}`);
      } else {
        this.element.style.removeProperty(`--tor-${name}-value`);
      }

      if (currentStart) {
        this.element.style.setProperty(`--tor-${name}-value-start`, `${currentStart}${currentEnd.unit}`);
      }

      if (attribute.options) {
        for (const [key, value] of Object.entries(attribute.options)) {
          switch (key) {
            case "pause":
              let temp = getValueData(attribute.options.pause);
              pauses[name].pause = temp.unit === "s" ? temp.value * 1000 : temp.value;
              hasPause = true;
              break;

            case "iterations":
              pauses[name].iterations = Number(attribute.options.iterations);
              break;

            case "delay":
              delays.push(`var(--tor-${name}-delay)`);
              this.element.style.setProperty(`--tor-${name}-delay`, `${value}`);
              break;

            default:
              this.element.style.setProperty(`--tor-${name}-${key}`, `${value}`);
              break;
          }
        }
      }

      names.push(name);
      durations.push(duration);
      timings.push(timing);
      directions.push(direction);
    }

    this.element.style.setProperty("animation-name", names.join(", "));
    this.element.style.setProperty("animation-duration", durations.join(", "));
    this.element.style.setProperty("animation-timing-function", timings.join(", "));
    this.element.style.setProperty("animation-direction", directions.join(", "));
    delays.length && this.element.style.setProperty("animation-delay", delays.join(", "));

    if (hasPause) {
      /** Do on animation iteration */
      this.element.onanimationiteration = (e) => {

        /** Do only it it's current element */
        if (e.target === this.element) {

          /** If loop has `iterations` option, add to `currentIteration` counter */
          if (pauses[e.animationName].iterations) {
            pauses[e.animationName].currentIteration++;
          }

          /** If `currentIteration` equals the predefined iteration */
          if (pauses[e.animationName].iterations === pauses[e.animationName].currentIteration) {
            pauses[e.animationName].currentIteration = 0;

            if (pauses[e.animationName].pause) {
              let pause = pauses[e.animationName].pause;
              indexes[pauses[e.animationName].index] = true;

              let states = [...Object.values(indexes)].map(item => { return item ? "paused" : "running" });
              this.element.style.setProperty("animation-play-state", states.join(", "));

              let time = setTimeout(() => {
                indexes[pauses[e.animationName].index] = false;
                states = [...Object.values(indexes)].map(item => { return !item ? "running" : "paused" });
                this.element.style.setProperty("animation-play-state", states.join(", "));
                clearTimeout(time)
              }, pause);
            }
          }
        }
      };
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Create `@media` CSS rules and add to global CSS_SET
   * ------------------------------------------------------------------------
   */

  _CSSAddToSet(attributes) {
    const cssObject = {};

    for (const items of Object.values(attributes)) {
      for (const attribute of Object.values(items)) {
        if (attribute.noCSSProcess) {
          continue;
        }

        let CSSOptions = "";
        let CSSAdditional = "";
        let CSSParent = "";
        let tempOptions = [];
        let CSSWrap = null;

        let CSSTrigger = attribute.trigger ? `-${attribute.trigger.name}` : "";
        let CSSTriggerAlias = attribute.trigger ? attribute.trigger.alias : "";
        let CSSPriority = attribute.priority ? " !important" : "";
        let CSSPropertyName = attribute.property.name;
        let CSSPropertyAlias = attribute.property.alias;

        /** Default if no values */
        if (!attribute.values) {
          attribute.values = {
            all: {
              end: {
                value: "0%",
              }
            }
          }
        }

        if (attribute.trigger && attribute.trigger.argument) {
          if (attribute.trigger.argument === "parent") {
            CSSParent = `[data-tor-parent~="${attribute.trigger.name}"]`;
          } else {
            CSSParent = `${attribute.trigger.argument}`;
          }
        }

        if (attribute.property.cssFunction) {
          CSSWrap = CSSPropertyName;
          CSSPropertyName = attribute.property.cssFunction;
        }

        /**
         * ---
         * If it's <custom> attribute. Example: `hover@padding(3rem)`
         * ---
         */

        if (attribute.isCustom) {
          /** Responsive <effect>. Example: `hover:xl::@margin(50%)` */
          for (const type of ["start", "end"]) {

            /** If attribute has <start> or <end> values */
            if (attribute.values.all[type]) {

              let triggerAlias = (type === "end") ? CSSTriggerAlias : "";
              let parent = CSSParent ? CSSParent + triggerAlias : "";

              /** default (start) value */
              addCSSRules({
                triggerAlias: triggerAlias,
                rule: CSSPropertyName,
                value: attribute.values.all[type].value,
                unit: attribute.values.all[type].unit,
                cssParent: parent,
              });

              /** Responsive <values>. Example: `hover:opacity(10% xl::50%)` */
              for (const [breakpoint, value] of Object.entries(attribute.values)) {
                addCSSRules({
                  resolution: breakpoint,
                  triggerAlias: triggerAlias,
                  rule: CSSPropertyName,
                  value: value[type].value,
                  unit: value[type].unit,
                  cssParent: parent,
                });
              }
            }
          }
        } else {

          /**
           * ---
           * Else, it's <static> attribute. Example: `hover:blur(sm)`
           * ---
           */

          let CSSValue = "";
          let CSSUnit = "";

          if (attribute.values) {
            /** Values */
            if (attribute.values.all.end) {
              CSSValue = attribute.values.all.end.value;
            } else {
              console.warn(`No default responsive value in "${attribute.original}" attribute. Setting "0" as default.`);
              CSSValue = "0";
            }

            /** Unit */
            if (attribute.values.all.end) {
              CSSUnit = attribute.values.all.end.unit;
            }
          }

          /**
           * Create CSS variables from options and push them to array
           */

          if (attribute.options) {
            for (const [key, value] of Object.entries(attribute.options)) {
              if (key !== "target") {
                tempOptions.push(`--tor-${attribute.property.name}-${key}: ${getCSSVariable({ property: key, value: value })}`);
              }
            }
            CSSOptions = `${tempOptions.length === 1 ? `${tempOptions};` : tempOptions.join(";")}`;
          }

          /**
           * Add additional CSS rules if applicable
           */

          if (CSS_PROPERTIES[attribute.property.name].additionalRules) {
            CSSAdditional = CSS_PROPERTIES[attribute.property.name].additionalRules;
          }

          /**
          * Add <default> CSS rule
          */

          let parent = CSSParent ? CSSParent + CSSTriggerAlias : "";

          addCSSRules.call(this, {
            trigger: CSSTrigger,
            triggerAlias: CSSTriggerAlias,
            rule: CSSPropertyAlias,
            value: CSSValue,
            unit: CSSUnit,
            cssParent: parent,
          });

          /**
          * Add <custom> CSS rule
          * Responsive <values>. Example: `hover:opacity(10% xl::50%)`
          */

          for (const [breakpoint, value] of Object.entries(attribute.values)) {
            if (breakpoint !== "all") {
              addCSSRules.call(this, {
                resolution: breakpoint,
                triggerAlias: CSSTriggerAlias,
                rule: CSSPropertyAlias,
                value: value.end.value,
                unit: value.end.unit,
                cssParent: parent,
              });
            }
          }
        }

        /**
         * Create and add CSS rules to CSS_SET
         */


        function addCSSRules(_) {

          const getCSS = getCSSVariable({
            property: _.propertyName || CSSPropertyName,
            value: _.value,
            unit: _.unit,
            wrap: CSSWrap
          })

          let css =
            `${_.cssParent ? _.cssParent : ""} [data-tor${_.selector || "~"}="${_.original || attribute.original}"]${!_.cssParent ? _.triggerAlias : ""} {
              ${_.rule}: ${getCSS}${_.priority || CSSPriority};
              ${/\:not/.test(_.triggerAlias) ? "" : _.options || CSSOptions}
              ${CSSAdditional}
            }
            ${_.options || CSSOptions ? `[data-tor${_.selector || "~"}="${_.original || attribute.original}"]{
              ${_.options || CSSOptions}
            }` : ""}
            `
              .replace(/ +/g, " ").replace(/\t|\n|\r+/g, "").replace(/\s*{\s*/g, "{").replace(/\s*}\s*/g, "}").replace(";;", ";").trim();

          let breakpoint = _.resolution || attribute.resolution;
          let set = CSS_SET.breakpoints[breakpoint];

          if (!set.has(css)) {
            insertStylesheet(`@media (min-width: ${CSS_BREAKPOINTS[breakpoint].value}${CSS_BREAKPOINTS[breakpoint].unit}) { ${css} }`);
            cssObject[breakpoint] = css;
            set.add(css);
          }
        }

      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Get element bounds
   * ------------------------------------------------------------------------
   */

  _getBounds() {
    this.bounds.calculated = false;
    let svgParent = this.element.ownerSVGElement;

    if (/^inview(.*?)(pull\.up|@transform=translateY\(\d)/.test(this.dataset)) {
      this.element.classList.add("tor-original-position");
    }

    /**
     * Chrome bug
     *
     * `intersectionObserver` doesn't work in Chrome, so we need to get the parent SVG rect
     */

    if (WINDOW.isUnsupportedSVG && svgParent) {
      if (svgParent) {
        let svg;

        svgParent.TORUS = svgParent.TORUS || {};
        svgParent.TORUS.svg = svgParent.TORUS.svg || {};

        this.is.svgChild = true;
        this.has.svgParent = svgParent;

        svg = svgParent.TORUS.svg;
        svg.children = svg.children || new Set();
        svg.children.add(this);

        if (WINDOW.isSafari) {
          // requestAnimationFrame(() => {
            setTimeout(() => {
              INTERSECTION_OBSERVER.observe(svgParent);
            }, 50);
          // })
        } else {
          INTERSECTION_OBSERVER.observe(svgParent);
        }
      }
    } else {
      INTERSECTION_OBSERVER.observe(this.element);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Set element bounds
   * ------------------------------------------------------------------------
   */

  _setBounds(bounds, svgParentRect) {
    let max;
    let B = this.bounds;
    let ratio = 1;
    let scrollLeft = WINDOW.scroll.x;
    let scrollTop = WINDOW.scroll.y;

    if (WINDOW.isUnsupportedSVG && svgParentRect) {
      ratio = svgParentRect.ratio;
      scrollLeft = svgParentRect.rect.offsetLeft;
      scrollTop = svgParentRect.rect.offsetTop;
    }

    if (bounds) {
      B.calculated  = true;
      B.rect        = bounds;
      B.width       = B.rect.width * ratio;
      B.height      = B.rect.height * ratio;
      B.top         = B.rect.y * ratio;
      B.left        = B.rect.x * ratio;
      B.right       = B.rect.right || B.left + B.width;
      B.bottom      = B.rect.bottom || B.top + B.height;
      B.offsetLeft  = B.left + scrollLeft;
      B.offsetTop   = B.top + scrollTop;
      B.offsetBottom = B.bottom + scrollTop;
      B.centerX     = B.offsetLeft + B.width / 2 - WINDOW.scroll.x;
      B.centerY     = B.offsetTop + B.height / 2 - WINDOW.scroll.y;

      if (/^inview(.*?)(pull\.up|@transform=translateY\(\d)/.test(this.dataset)) {
        B.offsetTopOriginal = B.offsetTop;
        this.element.classList.remove("tor-original-position");
      }

      // this._runAllEvents();
    } else {
      // TODO: test dynamic scrollTop for SVG on Chrome

      B.centerX = B.offsetLeft + B.width / 2 - WINDOW.scroll.x;
      B.centerY = B.offsetTop + B.height / 2 - WINDOW.scroll.y;
      this._runAllEvents("mouse");
    }

    max = getMaxSide(this);
    this.bounds.maxDiagonal = Math.round(max.corner);
    this.bounds.maxXSide = max.xSide;
    this.bounds.maxYSide = max.ySide;

    // TODO:
    // if (this.is.dynamicAttribute) {
    //   requestAnimationFrame(() => this.element.classList.remove("tor-hidden"))
    // }
  }

  /**
   * ------------------------------------------------------------------------
   * Add element to corresponding set
   * ------------------------------------------------------------------------
   */

  _addToElementsSet() {
    if (/scroll(.*?)\:(?!(.*?)class)/.test(this.dataset)) {
      SCROLL_ELEMENTS.add(this);
    }
    if (/scroll(.*?)class/.test(this.dataset)) {
      CLASS_SCROLL_ELEMENTS.add(this);
    }
    if (/mouse(.*?)\:/.test(this.dataset)) {
      MOUSE_ELEMENTS.add(this);
    }
    if (/inview(?!\()/.test(this.dataset)) {
      this.is.inviewElement = true;
      INVIEW_ELEMENTS.add(this);
    }
    if (this.element.ownerSVGElement) {
      SVG_ELEMENTS.add(this);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Run all events in loop to set the starting value immediately
   * ------------------------------------------------------------------------
   */

  _runAllEvents(event) {
    if (event) {
      if (this.attributes.dynamic && this.attributes.dynamic[event]) {
        this.run.event(event, true);
      }
    } else {
      for (const event of ["mouse", "scroll", "sensor", "inview"]) {
        if (event === "inview" && this.is.inviewElement) {
          this.run.inview();
        }
        if (this.attributes.dynamic && this.attributes.dynamic[event]) {
          this.run.event(event, true);
        }
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Run events for dynamic attributes
   * @example: `scroll:@scale(0;1)`
   * ------------------------------------------------------------------------
   */

  _runEvent(event, force) {
    if (!this.is.intersecting) {
      if(!force) {
        return;
      }
    }

    let cssName;
    let fullValue;
    let wrap = [];
    let dynamic = this.attributes.dynamic;

    if (!dynamic) {
      return;
    }

    dynamic.allStyles = dynamic.allStyles || {};

    /**
     * Create `styles` object that stores the `live` CSS values
     */

    for (const group of Object.values(dynamic[event])) {
      for (const attribute of Object.values(group)) {
        let all;
        let percents = getPercents(this, { event: event, options: attribute.options })[attribute.trigger.direction];

        dynamic.styles[attribute.resolution] = dynamic.styles[attribute.resolution] || {};
        dynamic.styles[attribute.resolution][attribute.property.name] = dynamic.styles[attribute.resolution][attribute.property.name] || {};

        /**
         * Declare CSS styles
         */

        /** Attribute has multi values defined by `...` */
        if (attribute.values.multi) {
          all = [];

          for (let i in attribute.values.all.start.value) {
            let GV = getValuesForCurrentResolution(attribute, percents, i);
            all.push(`${GV.value}${GV.unit}`);
          }

          all = attribute.values.cssFunction ? `${attribute.values.cssFunction}(${all.join(attribute.joinSymbol)})` : all.join(" ");
        } else {
          let GV = getValuesForCurrentResolution(attribute, percents);
          dynamic.styles[attribute.resolution][attribute.property.name].cssFunction = attribute.property.cssFunction;
          all = `${GV.value}${GV.unit}`;
        }

        dynamic.styles[attribute.resolution][attribute.property.name][`${event}${attribute.trigger.direction}`] = all;
        dynamic.styles[attribute.resolution][attribute.property.name].targets = attribute.options.target;
        dynamic.styles[attribute.resolution][attribute.property.name].priority = attribute.priority || "";
      }
    }

    /**
     * Loop trough all breakpoints from the current one down, and find the first object from the `styles`, that matches
     * the resolution from the loop
     */

    for (let i = CSS_BREAKPOINTS[WINDOW.resolution.name].id; i >= 0; i--) {
      let availableBreakpoints = Object.keys(CSS_BREAKPOINTS).find(key => CSS_BREAKPOINTS[key].id === i);

      if (dynamic.styles[availableBreakpoints]) {
        dynamic.currentStyles.add(Object.keys(dynamic.styles[availableBreakpoints])[0]);
        dynamic.allStyles[Object.keys(dynamic.styles[availableBreakpoints])[0]] = dynamic.styles[availableBreakpoints];
        break;
      } else {
        dynamic.currentStyles.clear();
      }
    }

    /**
     * Loop trough <all> available styles for the attribute and find if the <currentStyle> is used or not
     */

    for (const [name, style] of Object.entries(dynamic.allStyles)) {
      if (dynamic.currentStyles.has(name)) {
        assignCSS.call(this, style, "add");
      } else {
        assignCSS.call(this, style, "remove");
      }
    }

    /**
     * Add or remove the CSS style from the target element
     */

    function assignCSS(style, method) {
      let obj = {};
      for (const [name, value] of Object.entries(style)) {
        let tempValue = [];
        cssName = name;

        switch (method) {
          case "add": {
            for (const event of ["mouseall", "mousex", "mousey", "scrollall", "scrollx", "scrolly", "sensorall", "sensorx", "sensory"]) {
              value[event] && tempValue.push(value[event]);
            }

            fullValue = tempValue.length > 1 ? `calc(${tempValue.join(" + ")})` : tempValue[0];

            if (value.cssFunction) {
              cssName = value.cssFunction;
              let perspective = /rotate/.test(name) ? " perspective(1000px)" : "";
              wrap.push(`${name}(${fullValue}) ${perspective}`);
            }

            /** Group multiple events together */
            obj[cssName] = {
              value: value.cssFunction ? wrap.join(" ") : fullValue,
              priority: value.priority,
            };

            if (value.targets) {
              for (const target of value.targets) {
                for (const [name, value] of Object.entries(obj)) {
                  target.style.setProperty(name, value.value, value.priority);
                }
              }
            } else {
              for (const [name, value] of Object.entries(obj)) {
                this.element.style.setProperty(name, value.value, value.priority);
              }
            }

            break;
          }

          case "remove": {
            if (value.targets) {
              for (const target of value.targets) {
                if (value.cssFunction) {
                  target.style.this.element.style[value.cssFunction] = target.style[value.cssFunction].replace(target.style[value.cssFunction], "");
                }
                target.style.removeProperty(cssName);
              }
            } else {
              if (value.cssFunction) {
                this.element.style[value.cssFunction] = this.element.style[value.cssFunction].replace(this.element.style[value.cssFunction], "");
              }
              this.element.style.removeProperty(cssName);
            }

            delete dynamic.allStyles[cssName];
            break;
          }
        }
      }
    }
  }

  /**
   * ------------------------------------------------------------------------------------------------
   * CLASS ACTIONS
   * ------------------------------------------------------------------------------------------------
   */

  /**
   * ------------------------------------------------------------------------
   * Class: Add event listeners
   * ------------------------------------------------------------------------
   */

   _CLASSAddListeners(array) {
     const bodyIO = new IntersectionObserver((entries) => {
       WINDOW.documentHeight = Math.round(entries[0].boundingClientRect.height);

       this._CLASSCreateActions();

       if (array.some(item => /click:/.test(item))) {
         this.element.addEventListener("click", this._onClassClick.bind(this));
       }
       if (array.some(item => /hover:/.test(item))) {
         this.element.addEventListener("mouseenter", this._onClassMouseEnter.bind(this));
         this.element.addEventListener("mouseleave", this._onClassMouseLeave.bind(this));
       }
       if (array.some(item => /timeout:/.test(item))) {
         this._onClassTimeout();
       }
     });

     bodyIO.observe(document.documentElement);
  }

  /**
   * ------------------------------------------------------------------------
   * Class: Create `actions` object that stores the necessary data that
   * will be used when user performs a <trigger>
   * ------------------------------------------------------------------------
   */

  _CLASSCreateActions() {
    for (const attributes of Object.values(this.attributes.class)) {
      for (const attribute of Object.values(attributes.scroll ? attributes.scroll : attributes)) {
        let start = null;
        let end = null;
        let unit = null;

        if (attribute.options.start) {
          start = getValueData(attribute.options.start);
        }
        if (attribute.options.end) {
          end = getValueData(attribute.options.end);
        }

        switch (attribute.trigger.name) {
          case "timeout":
            if (start) {
              start = start.unit === "s" ? start.value * 1000 : start.value;
            }
            if (end) {
              end = end.unit === "s" ? end.value * 1000 : end.value;
            }
            break;

          default:
            if (start) {
              unit = start.unit;
              start = start.value;
            }
            if (end) {
              unit = end.unit;
              end = end.value;
            }
            break;
        }

        attribute.actions = {
          method: /class\.(.*?)$/.exec(attribute.property.name)[1],
          classes: attribute.values.all.original.split("░"),
          target: attribute.options.target,
          trigger: attribute.trigger.name,
          start: start,
          end: end,
          unit: unit,
          disable: attribute.options.disable,
        }
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: On click
   * ------------------------------------------------------------------------
   */

  _onClassClick() {
    for (const attribute of Object.values(this.attributes.class.click)) {
      this._CLASSTriggerNewState(attribute);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: On mouse enter (hover)
   * ------------------------------------------------------------------------
   */

  _onClassMouseEnter() {
    for (const attribute of Object.values(this.attributes.class.hover)) {
      this._CLASSTriggerNewState(attribute);
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: On mouse leave (hover out)
   * ------------------------------------------------------------------------
   */

  _onClassMouseLeave() {
    for (const attribute of Object.values(this.attributes.class.hover)) {
      if (attribute.actions.method === "toggle") {
        this._CLASSTriggerOldState(attribute);
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: On time out
   * ------------------------------------------------------------------------
   */

  _onClassTimeout() {
    for (const attribute of Object.values(this.attributes.class.timeout)) {
      let start = attribute.actions.start;
      let end = attribute.actions.end;

      attribute.time = setTimeout(() => {
        /** Trigger active state */
        this._CLASSTriggerNewState(attribute);

        /** If timeOut has end value */
        if (end) {
          attribute.time = setTimeout(() => {

            /** Trigger state back to inactive (original) */
            this._CLASSTriggerOldState(attribute);

            clearTimeout(attribute.time);
          }, end);
        }
        else {
          clearTimeout(attribute.time);
        }

      }, start);

    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: On scroll
   * ------------------------------------------------------------------------
   */

   _onClassScroll() {
    for (const attribute of Object.values(this.attributes.class.scroll.scroll)) {
      if (attribute.actions) {
        let scrolled = WINDOW.scroll.y;

        if (attribute.actions.unit === "%") {
          scrolled = WINDOW.scroll.y / (WINDOW.documentHeight - WINDOW.height) * 100;
        }

        if (attribute.actions.end) {
          if (scrolled >= attribute.actions.start && scrolled <= attribute.actions.end) {
            checkScroll.call(this, attribute, "in");
          } else {
            checkScroll.call(this, attribute, "out");
          }
        } else {
          if (scrolled >= attribute.actions.start) {
            checkScroll.call(this, attribute, "in");
          } else {
            if (attribute.actions.method === "toggle") {
              attribute.actions.done && toggle.call(this, attribute, true);
            }
          }
        }
      }
    }

    function checkScroll(attribute, method) {
      switch (method) {
        case "in":
          if (attribute.actions.method === "toggle") {
            !attribute.actions.done && toggle.call(this, attribute, false);
          } else {
            toggle.call(this, attribute, false);
          }
          break;

        case "out":
          if (attribute.actions.method === "toggle") {
            attribute.actions.done && toggle.call(this, attribute, true);
          } else {
            toggle.call(this, attribute, true);
          }
          break;
      }
    }

    function toggle(attribute, done) {
      if (!done) {
        this._CLASSTriggerNewState(attribute);
        attribute.actions.done = true;
      }
      if (done) {
        this._CLASSTriggerOldState(attribute);
        attribute.actions.done = false;
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: Trigger new state (add/remove/toggle class)
   * ------------------------------------------------------------------------
   */

  _CLASSTriggerNewState(attribute) {
    if (this.element.torClassDisabled) {
      return;
    }

    for (const target of getIterableElement(attribute.actions.target)) {
      if (attribute.priority) {
        setTimeout(() => {
          [...attribute.actions.classes].map(_class => target.classList[attribute.actions.method](_class) );
        }, 10);
      } else {
        [...attribute.actions.classes].map(_class => target.classList[attribute.actions.method](_class) );
      }

      if (attribute.actions.disable) {
        target.torClassDisabled = true;
      }
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Class: Trigger old state (revert classList back to original)
   * ------------------------------------------------------------------------
   */

  _CLASSTriggerOldState(attribute) {
    if (this.element.torClassDisabled) {
      return;
    }

    let newMethod;

    switch (attribute.actions.method) {
      case "add":
        newMethod = "remove";
        break;

      case "remove":
        newMethod = "add";
        break;

      default:
        newMethod = "toggle";
        break;
    }

    for (const target of getIterableElement(attribute.actions.target)) {
      [...attribute.actions.classes].map(_class => target.classList[newMethod](_class) );
    }
  }

  /**
   * ------------------------------------------------------------------------
   * Refresh
   * ------------------------------------------------------------------------
   */

  _refresh() {
    this.get.bounds();
    this._runAllEvents();
    this._LOOPCreate();
  }

  /**
   * ------------------------------------------------------------------------------------------------------------------------------------------------
   * Public functions
   * ------------------------------------------------------------------------------------------------------------------------------------------------
   */

  static refresh(elements) {
    INTERSECTION_OBSERVER.disconnect();

    callFunction({
      elements: getIterableElement(elements || "[data-tor]"),
      object: "Main",
      fn: "_refresh",
    });
  }

  /**
   * ------------------------------------------------------------------------------------------------------------------------------------------------
   * Initialization
   * ------------------------------------------------------------------------------------------------------------------------------------------------
   */

  static init(elements, options) {
    /** Get only elements that have not been initialized - doesn't have TORUS.Main class */
    elements = getIterableElement(elements || "[data-tor]");

    if (elements) {
      elements = elements.filter(item => { return !item.TORUS || !item.TORUS.Main });
      initClass({ name: "Main", elements: elements});
    }

  }
}

MUTATION();

export default TORUS.Main;