/**
 * ------------------------------------------------------------------------
 * Background Image
 * (c) Torus Kit
 * ------------------------------------------------------------------------
 */

import TORUS from "./namespace";

import {
  getIterableElement,
  initClass,
} from "./util";

TORUS.BgImage = class {
  constructor(element, options) {
    this.element = element;
    this.bgImageElement = element.querySelector(".bg-img");

    if(this.bgImageElement) {
      this.element.style.setProperty("background-image", `url('${this.bgImageElement.getAttribute("src")}')`);
    }

    if(options) {
      this.element.style.setProperty("background-image", `url('${options}')`);
    }
  }

  static init(elements, options) {
    elements = getIterableElement(elements || ".has-bg-img, .has-bg-pattern");
    initClass({name: "BgImage", elements: elements, options: options});
  }
}

export default TORUS.BgImage;